<template>
  <b-tabs content-class="pt-1" pills>
    <b-tab active>
      <template #title>
        <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">部门列表</span>
      </template>
      <div>
        <b-card-code title="部门列表">
          <!-- search input -->

          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.modal-login
            variant="outline-primary"
          >
            添加部门
          </b-button>

          <b-modal
            id="modal-login"
            cancel-variant="outline-secondary"
            ok-title="添加"
            cancel-title="关闭"
            centered
            no-close-on-backdrop
            title="添加部门"
            @ok="adddep"
          >
            <b-form>
              <b-form-group>
                <label for="module">部门名称:</label>
                <b-form-input
                  id="module"
                  placeholder="请输入部门名称"
                  v-model="name"
                />
              </b-form-group>

              <b-form-group label="上级部门" label-for="dname">
                <b-form-select
                  v-model="roleSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="roleOptions"
                  :reduce="(val) => val.value"
                  :clearable="false"
                  input-id="user-dname"
                />
              </b-form-group>
              <b-form-group label="备注" label-for="remark">
                <b-form-textarea
                  id="textarea-default"
                  v-model="remark"
                  placeholder="备注"
                  rows="3"
                />
              </b-form-group>
            </b-form>
          </b-modal>
          <div class="custom-search d-flex justify-content-end">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">search</label>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="搜索"
                  type="text"
                  class="d-inline-block"
                  @input="handleSearch"
                />
              </div>
            </b-form-group>
          </div>
          <!-- table -->
          <vue-good-table
            mode="remote"
            :columns="columns"
            :rows="rows"
            :rtl="direction"
            :search-options="{
              enabled: true,
              externalQuery: searchTerm,
            }"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true,
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true,
              selectAllByGroup: true,
            }"
            :pagination-options="{
              enabled: true,
            }"
            @on-sort-change="onSortChange"
            @on-selected-rows-change="selectionChanged"
          >
            <template slot="table-row" slot-scope="props">
              <!-- Column: Name -->
              <span
                v-if="props.column.field === 'wechatnick'"
                class="text-nowrap"
              >
                <b-avatar :src="props.row.avatar" class="mx-1" />
                <span class="text-nowrap">{{ props.row.wechatnick }}</span>
              </span>

              <!-- Column: Status -->
              <span v-else-if="props.column.field === 'isonline'">
                <b-badge :variant="statusVariant(props.row.isonline)">
                  {{ props.row.isonline == "0" ? "在线" : "离线" }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'createTime'">
                {{ timestampToTime(props.row.createTime) }}
              </span>
              <!-- Column: Action -->
              <span v-else-if="props.column.field === 'action'">
                <span>
                  <b-dropdown
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item @click="golink(props.row.id)">
                      <feather-icon icon="Edit2Icon" class="mr-50" />
                      <span>编辑</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="deletebyid(props.row.id)">
                      <feather-icon icon="TrashIcon" class="mr-50" />
                      <span>删除</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <!-- page length -->
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> 显示 </span>
                  <b-form-select
                    v-model="pageLength"
                    :options="pages"
                    class="mx-1"
                    @input="handlePageChange"
                  />
                  <span class="text-nowrap"> {{ total }} 条 </span>
                </div>

                <!-- pagination -->
                <div>
                  <b-pagination
                    :value="1"
                    :total-rows="total"
                    :per-page="pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="handleChangePage"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card-code>
      </div>
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon icon="SettingsIcon" size="16" class="mr-0 mr-sm-50" />
        <span class="d-none d-sm-inline">参数配置</span>
      </template>
      <div>
        <b-card-code>
          <div>
            部门列表
            <b-form-select
              v-model="roleSelect"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="roleOptions"
              :reduce="(val) => val.value"
              :clearable="false"
              input-id="user-dname"
              @change="config"
            />
          </div>
        </b-card-code>
        <b-card-code>
          <div class="demo-inline-spacing" style="width: 250px">
            <b-form-checkbox
              v-model="selected"
              value="1001"
              class="custom-control-primary"
            >
              自动抢红包
            </b-form-checkbox>

            <b-form-checkbox
              v-model="selected"
              value="1002"
              class="custom-control-secondary"
            >
              自动通过好友请求
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selected"
              value="1003"
              class="custom-control-success"
            >
              自动通过群邀请链接请求
            </b-form-checkbox>

            <b-form-checkbox
              v-model="selected"
              value="1004"
              class="custom-control-danger"
            >
              自动收账
            </b-form-checkbox>

            <b-form-checkbox
              v-model="selected"
              value="1006"
              class="custom-control-warning"
            >
              聊天关键词自动回复
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selected"
              value="1007"
              class="custom-control-info"
            >
              群聊关键词自动回复
            </b-form-checkbox>

            <b-form-checkbox
              v-model="selected"
              value="1008"
              class="custom-control-primary"
            >
              自动点赞
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selected"
              value="1009"
              class="custom-control-secondary"
            >
              自动打招呼

              <b-form-input
                v-model="sayhi"
                style="float: left"
                id="basicInput"
                placeholder="输入打招呼消息"
                autocomplete="off"
              />
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selected"
              value="1010"
              class="custom-control-success"
            >
              扫码被动加好友上限
              <cleave
                id="number"
                v-model.number="number"
                class="form-control"
                :raw="false"
                :options="options.number"
                placeholder="10,000"
                autocomplete="off"
              />
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selected"
              value="1011"
              class="custom-control-primary"
            >
              开启返利
            </b-form-checkbox>
            <b-form-checkbox
              v-model="selected"
              value="1012"
              class="custom-control-secondary"
            >
              首订单发红包
            </b-form-checkbox>
          </div>
          <div>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="save"
            >
              保存
            </b-button>
          </div>
        </b-card-code>
      </div>
    </b-tab></b-tabs
  >
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import Cleave from "vue-cleave-component";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BTabs,
  BTab,
  BTable,
  BDropdown,
  BForm,
  BModal,
  BFormCheckbox,
  VBModal,
  BFormTextarea,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";
import AccountConfig from "../rebate/AccountConfig.vue";
export default {
  components: {
    BCardCode,
    BFormTextarea,
    VueGoodTable,
    BTabs,
    BTab,
    Cleave,
    BTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BFormCheckbox,
    BDropdownItem,
    BDropdown,
    vSelect,
    BForm,
    BModal,
    VBModal,

    ToastificationContent,
    AccountConfig,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      number: 0,
      total: 0,
      cid: 0,
      desc: "",
      name: "",
      remark: "",
      sayhi: "",
      selectid: [],
      selected: [],
      search: "",
      roleSelect: "",
      roleOptions: [],
      pages: ["10", "20", "40", "100"],
      options: {
        number: {
          numeral: true,
        },
      },
      columns: [
        {
          label: "部门名称",
          field: "name",
        },
        {
          label: "上级部门",
          field: "pname",
        },
        {
          label: "创建时间",
          field: "createTime",
        },
        {
          label: "备注信息",
          field: "description",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0: "light-success",
        1: "light-danger",

        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },

    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    this.cid = user.cid;
    useJwt
      .querydepartmentbycid({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        this.total = res.data.data.length;
        this.rows = res.data.data;
      });
  },
  methods: {
    config() {
      this.sayhi = "";
      this.number = "";
      this.selected = [];
      useJwt
        .AutoTaskDetailList({
          cid: this.cid,
          did: this.roleSelect,
        })
        .then((res) => {
          console.log("res!!!!!!!!!", res.data.data);
          const forArr = res.data.data;
          forArr.forEach((item, i) => {
            this.selected.push(item.auto_type);
            if (item.auto_type == 1009) {
              this.sayhi = item.remark;
            }
            if (item.auto_type == 1010) {
              this.number = item.remark;
            }
          });
          console.log("this.selected", this.selected);
        });
    },
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;

      const D = `${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      } `;
      const h = `${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:`;
      const m = `${
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
      }:`;
      const s =
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      return Y + M + D + h + m + s;
    },
    selectionChanged(params) {
      this.selectid = params.selectedRows;
      console.log("JSON.stringify(params),", JSON.stringify(params));
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Hello user! You have clicked on row ${params.selectedRows.length}`,
          icon: "UserIcon",
          variant: "success",
        },
      });
    },
    save() {
      for (var i = 0; i < this.selected.length; i++) {
        var re = "";
        if (this.selected[i] == "1009") {
          re = this.sayhi;
        }
        if (this.selected[i] == "1010") {
          re = this.number;
        }
        console.log("roleSelect", this.roleSelect);
        console.log("selected[i]", this.selected[i]);
        console.log("re", re);
        useJwt.AutoTaskUpdate({
          cid: this.cid,
          did: this.roleSelect,
          auto_type: this.selected[i],
          state: 1,
          remark: re,
        });
      }
      this.$swal({
        icon: "success",
        title: "修改成功!",
        text: "修改成功",
        customClass: {
          confirmButton: "btn btn-success",
        },
      });
    },
    deletebyid(id) {
      this.$swal({
        title: "确定要删除吗?",
        text: "删除后无法恢复!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          useJwt.deldepartmentbyid({ ids: id }).then((res) => {
            if (res.data.code === 0) {
              this.$swal({
                icon: "success",
                title: "删除!",
                text: "删除成功",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              useJwt
                .querydepartmentbycid({
                  cid: this.cid,
                  page: 1,
                  rows: this.pageLength,
                })
                .then((res) => {
                  this.total = res.data.data.length;
                  this.rows = res.data.data;
                });
            } else {
              this.$swal({
                icon: "error",
                title: "删除失败!",
                text: `${res.data.msg}`,
                customClass: {
                  confirmButton: "btn btn-error",
                },
              });
            }
          });
        }
      });
    },
    adddep() {
      useJwt
        .adddepartment({
          cid: this.cid,
          name: this.name,
          pid: this.roleSelect,
          description: this.remark,
        })
        .then((res) => {
          console.log("提交结果", res);
          if (res.data.code == 0) {
            this.$swal({
              icon: "success",
              title: "添加部门成功!",
              text: "添加成功",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            useJwt
              .querydepartmentbycid({
                cid: this.cid,
                page: 1,
                rows: this.pageLength,
              })
              .then((res) => {
                this.total = res.data.data.length;
                this.rows = res.data.data;
              });
          }
        });
    },
    handleSearch(searching) {
      useJwt
        .querydepartmentbycid({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          name: searching,
        })
        .then((res) => {
          this.total = res.data.data.length;
          this.rows = res.data.data;
        });
      this.search = searching;
    },
    handleChangePage(page) {
      useJwt
        .querydepartmentbycid({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          name: this.search,
        })
        .then((res) => {
          this.total = res.data.data.length;
          this.rows = res.data.data;
        });
    },
    handlePageChange(active) {
      useJwt
        .querydepartmentbycid({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          name: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.total = res.data.data.length;
          this.rows = res.data.data;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }

      useJwt
        .querydepartmentbycid({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          name: this.search,
        })
        .then((res) => {
          this.total = res.data.data.length;
          this.rows = res.data.data;
        });
    },
  },
  mounted() {
    useJwt.queryByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;

        forArr.forEach((item, i) => {
          this.roleOptions.push({ text: item.name, value: item.id });
        });
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
